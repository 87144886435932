// // Variables
//
// $primary: #ef604d;
// $secondary: #599aa6;
// $success: #57d685;
// $warning: #ffae00;
// $alert: #ec5840;
//
// $proposals: #238FF7;
// $actions: #57D685;
// $debates: #FA6C96;
// $meetings: #FABC6C;
//
// $twitter: #55acee;
// $facebook: #3b5998;
// $google: #dd4b39;

@import "email-custom";
@import "fontface";

// .logo-cityhall {
//   position: static;
//   margin: 0;
//   @media print, screen and (min-width: 40em) {
//     text-align: left;
//   }
// }


.initiative-types-select {
  white-space: normal !important;
  word-break: break-all !important;
  overflow-wrap: break-word !important;

  padding-bottom: 0;

  option {
    position: relative;
    display: block;
    margin: 0 -15px 5px 0;
    padding: 5px 10px;
    font-size: 90%;
    line-height: 17px;
    color: $secondary;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    cursor: pointer;
    white-space: normal !important;
    word-break: break-all !important;
    overflow-wrap: break-word !important;
    text-overflow: clip;
    overflow-x: hidden;

    &:hover {
      background: #e8e8e8;
    }
  }

  &:focus {
    border-color: #238FF7;

    option:checked {
      background: #238FF7;
      color: white;
    }
  }
}

.highlighted_proposals .card--proposal {
  width: 100%;
}

// Hide autorisation handler profile tab
// #user-settings-tabs > li:nth-child(3) {
//   display: none;
// }

.main-footer__badge {
  text-align: center;
}

@media print, screen and (min-width: 64em) {
  .main-footer__badge {
    position: relative;
    text-align: left;
  }
}

.topbar div {
  align-items: center;
}

.mini-footer .row {
  display: flex;
  align-items: center;
  justify-content: center;

  .decidim-logo {
    display: flex;
    flex-grow: 0;
    width: auto;
    margin: 0 .5rem;
  }

  img {
    height: 1rem;
    margin-top: -0.25rem;
  }

  .mf-text {
    font-size: 80%;
    line-height: 1rem;

    a {
      font-weight: 600;

      &:hover {
        color: white;
      }
    }
  }
}

.button--social.button--google {
  padding-top: 3px;
}

.comment__content {
  overflow-wrap: break-word;
  hyphens: auto;
}

.card {
  overflow-wrap: break-word;
  hyphens: auto;
}
