@font-face {
    font-family: 'FS Elliot';
    src: url('fonts/FSElliot/FSElliot-Light.eot');
    src: url('fonts/FSElliot/FSElliot-Light.eot?#iefix') format('embedded-opentype'),
        url('fonts/FSElliot/FSElliot-Light.woff2') format('woff2'),
        url('fonts/FSElliot/FSElliot-Light.woff') format('woff'),
        url('fonts/FSElliot/FSElliot-Light.ttf') format('truetype'),
        url('fonts/FSElliot/FSElliot-Light.svg#FSElliot-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FS Elliot';
    src: url('fonts/FSElliot/FSElliot-Bold.eot');
    src: url('fonts/FSElliot/FSElliot-Bold.eot?#iefix') format('embedded-opentype'),
        url('fonts/FSElliot/FSElliot-Bold.woff2') format('woff2'),
        url('fonts/FSElliot/FSElliot-Bold.woff') format('woff'),
        url('fonts/FSElliot/FSElliot-Bold.ttf') format('truetype'),
        url('fonts/FSElliot/FSElliot-Bold.svg#FSElliot-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FS Elliot';
    src: url('fonts/FSElliot/FSElliot.eot');
    src: url('fonts/FSElliot/FSElliot.eot?#iefix') format('embedded-opentype'),
        url('fonts/FSElliot/FSElliot.woff2') format('woff2'),
        url('fonts/FSElliot/FSElliot.woff') format('woff'),
        url('fonts/FSElliot/FSElliot.ttf') format('truetype'),
        url('fonts/FSElliot/FSElliot.svg#FSElliot') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
